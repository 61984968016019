import axios from 'axios'
import React, { useEffect, useState } from 'react'
// import { RotatingLines } from "react-loader-spinner";
import { BiSearch } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { api } from '../../utils/apiToken'
import classes from './Table.module.css'

export default function Table() {
    const navigate = useNavigate()

    const [search, setSearch] = useState('')
    const [companyData, setCompanyData] = useState([])
    const [pending, setPending] = useState(true)
    const [handleSearchResult, setHandleSearchResult] = useState(false)

    // const [id, setId] = useState('')
    // const [activeDeleteData, setActiveDeleteData] = useState(false)
    // const [activeUpdateData, setActiveUpdateData] = useState(false)

    const [pageSkip, setPageSkip] = useState(0)
    const [slNo, setSlNo] = useState(1)
    const [countData, setCountData] = useState('')

    const showDataDetails = (companyName) => {
        // navigate(`/CompanyDatabase/${companyName}`, { state: { name: companyName } })
    }

    const previousHandle = () => {
        if (pageSkip > 0) {
            setSlNo(slNo - 10)
            setPageSkip(pageSkip - 10)
            setHandleSearchResult(true)
        }
    }

    const nextHandle = async () => {
        if (pageSkip < Math.floor(countData / 10) * 10) {
            setSlNo(slNo + 10)
            setPageSkip(pageSkip + 10)
            setHandleSearchResult(true)
        }
    }
    // console.log(api)

    useEffect(() => {
        const handleSearch = async () => {
            if (search && handleSearchResult) {
                const company_data = await axios.get(
                    `${api}/company_database/get_all_data_of_a_company/${search}?skip=${pageSkip}&limit=10`,
                    { withCredentials: true }
                )
                const company_Data = await company_data.data
                setCompanyData(company_Data.Data)
                setPending(false)
                setCountData(company_Data.Count)
                setHandleSearchResult(false)
            } else if (search === '') {
                const company_data = await axios.get(
                    `${api}/company_database/get_all_data_of_a_company/None?skip=${pageSkip}&limit=10`,
                    { withCredentials: true }
                )
                const company_Data = await company_data.data

                setCompanyData(company_Data.Data)
                setPending(false)
                setCountData(company_Data.Count)
            }
        }
        handleSearch()
    }, [search, pageSkip, handleSearchResult])

    const handleSearch = async (e) => {
        e.preventDefault()
        setPageSkip(0)
        setSlNo(1)
        setHandleSearchResult(true)
        // console.log(search)
        // console.log(slNo)
        // console.log(pageSkip)

        // if (search !== '' && pageSkip !== null) {
        //     const company_database = await axios.get(
        //         `${api}/company_database/get_all_data_of_a_company/${search}?skip=${pageSkip}&limit=10`,
        //         {
        //             headers: {
        //                 'Access-Control-Allow-Origin': frontEnd,
        //             },
        //             withCredentials: true,
        //         }
        //     )
        //     const company_Data = await company_database.data
        //     console.log(company_Data)
        //     setCompanyData(company_Data.Data)
        //     setCountData(company_Data.Count)
        //     setPending(false)
        //     setPageSkip(0)
        //     setSlNo(1)
        // }
        // else if (search === '') {
        //     console.log('entered')
        //     const company_data = await axios.get(
        //         `${api}/company_database/get_all_data_of_a_company/None?skip=${pageSkip}&limit=10`
        //     )
        //     const company_Data = await company_data.data

        //     setCompanyData(company_Data.Data)
        //     setPending(false)
        //     setCountData(company_Data.Count)
        // }
    }

    return (
        <>
            <div className={classes.AllData}>
                <div>
                    <div>
                        <form onSubmit={(e) => handleSearch(e)}>
                            <input
                                type="text"
                                placeholder="Search by company ..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                // required
                            />
                            {search && (
                                <button type="button" className={classes.searchBtnRemove} onClick={() => setSearch('')}>
                                    X
                                </button>
                            )}
                            <button type="submit" className={classes.searchBtn}>
                                <BiSearch className={classes.searchIcon} />
                            </button>
                        </form>
                    </div>
                    {companyData && companyData.length !== 0 && !pending ? (
                        <div className={classes.tableWrapper}>
                            <table>
                                <thead>
                                    <tr>
                                        <th id={classes.index}>#</th>
                                        <th>Company</th>
                                        <th>Industry</th>
                                        <th>Sub Industry</th>
                                        <th>Company Type</th>
                                        <th>Company Origin</th>
                                        <th>Country Origin</th>
                                        <th>City/Division</th>
                                        <th>Year of Origin</th>
                                        <th>Year of Inception of Bangladesh operation</th>
                                        <th>Product/Service Description</th>
                                        <th>Major Brands</th>
                                        <th>Company Description</th>
                                        <th>Founder</th>
                                        <th>Manpower</th>
                                        <th>Disributorship/Business Partner</th>
                                        <th>DSC Listing Year</th>
                                        <th>CSE Listing Year</th>
                                        <th>Authorized Capital (Cr. BDT)</th>
                                        <th>Paid Up Capital</th>
                                        <th>Number of Securities (Million)</th>
                                        <th>Partner/Investor 1</th>
                                        <th>Shareholding (%)</th>
                                        <th>Partner/Investor 2</th>
                                        <th>Shareholding (%)</th>
                                        <th>Partner/Investor 3</th>
                                        <th>Shareholding (%)</th>
                                        <th>Partner/Investor 4</th>
                                        <th>Shareholding (%)</th>
                                        <th>Partner/Investor 5</th>
                                        <th>Shareholding (%)</th>
                                        <th>HR Person 1</th>
                                        <th>HR Person 1 designation</th>
                                        <th>HR Person 1 official email</th>
                                        <th>HR Person 1 personal email</th>
                                        <th>HR Person 1 official number</th>
                                        <th>HR Person 1 personal number</th>
                                        <th>HR Person 2</th>
                                        <th>HR Person 2 designation</th>
                                        <th>HR Person 2 official email</th>
                                        <th>HR Person 2 personal email</th>
                                        <th>HR Person 2 official number</th>
                                        <th>HR Person 2 personal number</th>
                                        <th>HR Person 3</th>
                                        <th>HR Person 3 designation</th>
                                        <th>HR Person 3 official email</th>
                                        <th>HR Person 3 personal email</th>
                                        <th>HR Person 3 official number</th>
                                        <th>HR Person 3 personal number</th>
                                        <th>HR Person 4</th>
                                        <th>HR Person 4 designation</th>
                                        <th>HR Person 4 official email</th>
                                        <th>HR Person 4 personal email</th>
                                        <th>HR Person 4 official number</th>
                                        <th>HR Person 4 personal number</th>
                                        <th>HR Person 5</th>
                                        <th>HR Person 5 designation</th>
                                        <th>HR Person 5 official email</th>
                                        <th>HR Person 5 personal email</th>
                                        <th>HR Person 5 official number</th>
                                        <th>HR Person 5 personal number</th>

                                        <th>Address Corporate Office</th>
                                        <th>Address Main Factory Production</th>
                                        <th>Email</th>
                                        <th>Contact Details</th>
                                        <th>Website</th>
                                        <th>Other Info</th>
                                        <th>Reference1</th>
                                        <th>Reference2</th>
                                        <th>Reference3</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {companyData.map((data, index) => (
                                        <tr key={index}>
                                            <td id={classes.index}>{index + slNo}</td>
                                            <td
                                                id={classes.companyNameTd}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.company}
                                            </td>
                                            <td>{data.industry}</td>
                                            <td>{data.subindustry}</td>
                                            <td>
                                                <p
                                                    className={
                                                        data.company_type === 'Private'
                                                            ? classes.private
                                                            : data.company_type === 'Public'
                                                            ? classes.public
                                                            : ''
                                                    }>
                                                    {data.company_type}
                                                </p>
                                            </td>
                                            <td>{data.company_origin}</td>
                                            <td>{data.country_of_origin}</td>
                                            <td>{data.city_or_division}</td>
                                            <td>{data.year_of_origin}</td>
                                            <td>{data.year_of_inception_of_bangladesh_operation}</td>
                                            <td>
                                                <div className={classes.desc}>
                                                    {data.product_or_service_description}
                                                </div>
                                            </td>
                                            <td>{data.major_brands}</td>
                                            <td>
                                                <div className={classes.desc}>{data.company_description}</div>
                                            </td>

                                            <td>{data.founder}</td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.manpower}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.disributorship_or_business_partner}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.dse_listing_year}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.cse_listing_year}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.authorised_capital_cr_in_bdt}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.paid_up_capital_cr_in_bdt}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.number_of_securities_in_million}
                                            </td>
                                            {/* <td>
                                            <li>{data.partner_investor_1}</li>
                                            <li>Shareholding: {data.partner_investor_1_shareholding}%</li>
                                        </td> */}
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_1}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_1_shareholding}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_2}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_2_shareholding}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_3}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_3_shareholding}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_4}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_4_shareholding}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_5}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.partner_investor_5_shareholding}
                                            </td>

                                            {/* <td>
                                            <li>Name: {data.key_person_1}</li>
                                            <li>Designation: {data.key_person_1_designation}</li>
                                            <li>Official Email: {data.key_person_1_official_email}</li>
                                            <li>Personal Email: {data.key_person_1_personal_email}</li>
                                            <li>Official Contact: {data.key_person_1_official_number}</li>
                                            <li>Personal Contact: {data.key_person_1_personal_number}</li>
                                        </td> */}

                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_1}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_1_designation}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                <span id={classes.email1}>
                                                    {/* {data.key_person_1_official_email.split('@')[0]} */}
                                                    {data.key_person_1_official_email && 'awefazd'}
                                                </span>
                                                {data.key_person_1_official_email && '@'}
                                                <span id={classes.email2}>
                                                    {data.key_person_1_official_email.split('@')[1]}
                                                </span>
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                <span id={classes.email1}>
                                                    {/* {data.key_person_1_personal_email.split('@')[0]} */}
                                                    {data.key_person_1_personal_email && 'awefazd'}
                                                </span>
                                                {data.key_person_1_personal_email && '@'}
                                                <span id={classes.email2}>
                                                    {data.key_person_1_personal_email.split('@')[1]}
                                                </span>
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                <span id={classes.email2}>
                                                    {data.key_person_1_official_number.split('-')[0]}
                                                </span>
                                                {data.key_person_1_official_number && '-'}
                                                <span id={classes.email1}>
                                                    {data.key_person_1_official_number && '01523'}
                                                </span>
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                <span id={classes.email2}>
                                                    {data.key_person_1_personal_number.split('-')[0]}
                                                </span>
                                                {data.key_person_1_personal_number && '-'}
                                                <span id={classes.email1}>
                                                    {data.key_person_1_personal_number && '01523'}
                                                </span>
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_2}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_2_designation}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_2_official_email}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_2_personal_email}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_2_official_number}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_2_personal_number}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_3}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_3_designation}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_3_official_email}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_3_personal_email}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_3_official_number}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_3_personal_number}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_4}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_4_designation}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_4_official_email}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_4_personal_email}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_4_official_number}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_4_personal_number}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_5}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_5_designation}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_5_official_email}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_5_personal_email}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_5_official_number}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.key_person_5_personal_number}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.address_corporate_office}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.address_main_factory_production}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {/* {data.email_address.split(',', 3)[0].split('@')[0]}@
                                                {data.email_address.split(',', 3)[0].split('@')[1]}
                                                <p>---</p>
                                                {data.email_address.split(',', 3)[1].split('@')[0]}@
                                                {data.email_address.split(',', 3)[1].split('@')[1]}
                                                <p>---</p>
                                                {data.email_address.split(',', 3)[2].split('@')[0]}@
                                                {data.email_address.split(',', 3)[2].split('@')[1]} */}
                                                {/* <span id={classes.email2}>{data.email_address.split('@')[0]}</span>
                                                {data.email_address && '@'}
                                                <span id={classes.email2}>{data.email_address.split('@')[1]}</span> */}

                                                {data.email_address}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.contact_number}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.website}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.other_info}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.references_1}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.references_2}
                                            </td>
                                            <td
                                                id={classes.companyDetails}
                                                onClick={(e) => showDataDetails(data.company)}>
                                                {data.references_3}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className={classes.Dna}>
                            {/* <RotatingLines
                strokeColor="skyblue"
                strokeWidth="3"
                animationDuration="1"
                width="85"
                visible={true}
              /> */}
                            <p>No search results found!</p>
                        </div>
                    )}{' '}
                </div>
                <div className={classes.pagination}>
                    <button
                        disabled={slNo === 1 && true}
                        type="button"
                        onClick={() => {
                            previousHandle()
                        }}>
                        {'<< Previous'}
                    </button>
                    <button
                        disabled={Math.ceil(slNo / 10) === Math.ceil(countData / 10) ? true : false}
                        type="button"
                        onClick={() => nextHandle()}>
                        {'Next >>'}
                    </button>
                    <div>
                        <span style={{ color: 'var(--home)', fontWeight: 'bold' }}>Page: </span>
                        {Math.ceil(slNo / 10)}/{Math.ceil(countData / 10)}
                    </div>
                </div>
            </div>
        </>
    )
}
